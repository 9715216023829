
import {
  defineComponent,
  ref,
  computed,
  watch,
  onMounted,
  watchEffect,
} from "vue";
import { useStore } from "vuex";

import ModalConnect from "@/components/ModalConnect.vue";
import { event } from "vue-gtag";
import { useRouter } from "vue-router";

export default defineComponent({
  components: {
    ModalConnect,
  },

  setup() {
    const store = useStore();
    const router = useRouter();

    const openModal = ref(false);
    const NFTCount = ref(1);

    const web3 = computed(() => store.state.mint.web3);
    const account_address = computed(() => store.state.mint.account_address);
    const NFTPrice = computed(() => store.state.mint.NFTPrice);
    const limit = computed(() => store.state.mint.limit);
    const currentMinted = computed(() => store.state.mint.currentMinted);
    const supply = computed(() => store.state.mint.supply);
    const message = computed(() => store.state.mint.message);

    const balance = ref(0);

    const comp_limit = computed(() => limit.value);

    watch(account_address, async () => {
      if (account_address.value) {
        openModal.value = false;
        const balance_tmp = await web3.value.eth.getBalance(
          account_address.value
        );

        balance.value = Number(web3.value.utils.fromWei(balance_tmp, "ether"));
        return;
      }

      balance.value = 0;
    });

    watchEffect(() => {
      if (currentMinted.value >= supply.value) {
        router.push("/");
      }
    });

    onMounted(() => {
      event("Enter mint");
    });

    return {
      store,
      openModal,
      NFTCount,
      account_address,
      NFTPrice: computed(() => NFTPrice.value / 1e18),
      limit,
      currentMinted,
      supply,
      message,
      balance,
      comp_limit,
    };
  },
});
